.navbar-box {
  width: 30%;
  float: left;
  height: 100vh;
}

/* VERTICAL NAVBAR */

.logo-karynga {
  text-decoration: none;
  display: inline-block;
  color: black;
  font-weight: 900;
  margin-left: 30px;
  margin-top: 40px;
  font-size: 1.4rem;
}

.navbar {
  height: auto;
  margin-left: 40px;
  margin-top: 20px;
}

.navbar a:hover {
  font-weight: 700;
}

.obra,
.pinturas,
.expo,
.bio,
.libros,
.contacto {
  text-decoration: none;
  list-style-type: none;
  color: black;
  padding-left: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1rem;
}

.humanidad,
.criaturas,
.aglomeraciones,
.saturacion,
.drogadiccion,
.medula,
.sistema {
  text-decoration: none;
  font-size: 0.95em;
  color: black;
  padding-left: 40px;
  padding-bottom: 20px;
  font-weight: 500;
  cursor: pointer;
}

.sub-menu.active {
  display: inline-block;
  list-style-type: none;
  margin-left: 20px;
  margin-bottom: 20px;
  transition-duration: 0.7s;
  height: 190px;
}

.mobile-menu-icon,
.mobile-close-icon {
  display: none;
  transition: 0.35s;
}

.sub-menu {
  overflow: hidden;
  height: 0;
  transition-duration: 0.7s;
}

/* RESPONSIVE NAVBAR */

@media screen and (max-width: 768px) {
  .navbar-box {
    width: 100%;
    margin: auto;
    height: auto;
    float: none;
    transition: 0.7s;
  }

  .logo-karynga {
    display: inline-block;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    margin-top: 30px;
    font-size: 1.6rem;
  }

  .nav-links {
    display: none;
  }

  .nav-mobile-links {
    left: 0;
    top: 0;
    padding-top: 60px;
    height: 2000px;
    position: absolute;
    list-style: none;
    background-color: black;
    width: 100%;
    transition: 1s;
    z-index: 2000;
  }

  .sub-menu {
    display: block;
    height: auto;
    list-style-type: none;
    margin: auto;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sub-menu.active {
    display: block;
    overflow: none;
    height: auto;
    margin-left: 0;
    list-style-type: none;
    margin-bottom: 10px;
    transition-duration: 1.5s;
  }

  .obra,
  .pinturas,
  .expo,
  .bio,
  .libros,
  .contacto {
    text-decoration: none;
    color: white;
    text-align: center;
    font-weight: 900;
    cursor: pointer;
    font-size: 1.1rem;
  }

  .humanidad,
  .criaturas,
  .aglomeraciones,
  .saturacion,
  .drogadiccion,
  .medula,
  .sistema {
    text-align: center;
    text-decoration: none;
    font-size: 0.95em;
    text-align: center;
    color: white;
    padding-bottom: 20px;
    font-weight: 400;
    cursor: pointer;
  }

  .mobile-menu-icon {
    width: 25px;
    height: 25px;
    display: block;
    color: black;
    text-align: center;
    margin: auto;
    cursor: pointer;
    transition: 2s ease-in;
  }

  .mobile-close-icon {
    width: 40px;
    height: 40px;
    color: white;
    position: absolute;
    top: 50px;
    right: 40px;
    display: inline-block;
    cursor: pointer;
    z-index: 2000;
  }

  .mobile-menu-icon:hover {
    color: grey;
  }

  .mobile-close-icon:hover {
    color: grey;
  }
}
