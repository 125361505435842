* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Urbanist", sans-serif;
}

/* MAIN */

.section-container {
  width: 70%;
  float: left;
  animation: fade 1.5s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.article-container {
  height: auto;
  margin: 50px 0 0 50px;
}

.text-container {
  height: 900px;
  margin: 50px 60px 0 50px;
  font-weight: 400;
}

#text-contact a {
  text-decoration: none;
  list-style-type: none;
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
}

#text-contact li {
  padding-bottom: 20px;
}

/* IMAGE */

.wrapper {
  margin: auto;
  margin-top: 50px;
  padding-left: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  width: 250px;
  height: 250px;
  cursor: pointer;
  object-fit: cover;
}

.wrapper h2 {
  display: none;
}

/* MODAL IMAGE */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.95);
  display: flex;
  align-items: center;
}

.overlay > img {
  display: block;
  width: 900px;
  height: 650px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  color: white;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 100px;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  color: white;
  right: 100px;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}

.overlay-text {
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100px;
}

.overlay-text > h2 {
  display: block;
  text-align: center;
  color: white;
  line-height: 70px;
}

.dismiss {
  font-size: 0.9rem;
}

.material-symbols-outlined {
  cursor: pointer !important;
}

@media screen and (max-width: 1280px) {
  .overlay-arrows_right {
    right: 5%;
    top: 50%;
  }

  .overlay-arrows_left {
    left: 5%;
    top: 50%;
  }

  .overlay > img {
    display: block;
    width: 650px;
    height: 500px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .overlay-text {
    position: absolute;
    margin: auto;
    width: 80%;
    height: 100px;
    text-align: center;
    font-size: 1rem;
    top: 97%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .overlay-text > h2 {
    line-height: 20px;
  }
}

/* @media screen and (min-width: px) {
.overlay-arrows_right {
    right: 20%;
    top: 50%;
  }

  .overlay-arrows_left {
    left: 20%;
    top: 50%;
  }

  .overlay > img {
    display: block;
    width: 500px;
    height: 700px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .overlay-text {
    position: absolute;
    margin: auto;
    width: 80%;
    height: 100px;
    text-align: center;
    font-size: 1rem;
    top: 97%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .overlay-text > h2 {
    line-height: 20px;
  }
} */

@media screen and (max-width: 576px) {
  .section-container {
    width: 100%;
    margin-bottom: 400px;
    margin: auto;
    justify-content: center;
  }

  .text-contact {
    width: 100%;
    margin: auto;
    height: 500px;
    text-align: center;
  }

  .text-contact a {
    display: block;
  }

  .article-container {
    width: 100%;
    height: 70px;
    margin: auto;
  }

  .article-container > h2 {
    padding: 40px 0px;
    font-size: 1.5rem;
    text-align: center;
  }

  .text-container {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    height: 100vh;
    font-weight: 400;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    padding-left: 0;
    gap: 0;
  }

  .wrapper img {
    width: 320px;
    height: 320px;
    margin-bottom: 20px;
  }

  .overlay-arrows_right {
    top: 87%;
    right: 1%;
  }

  .overlay-arrows_left {
    top: 87%;
  }

  .overlay > img {
    display: block;
    width: 350px;
    height: 400px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .overlay-text {
    position: absolute;
    margin: auto;
    width: 80%;
    height: 100px;
    text-align: center;
    font-size: 0.7rem;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .overlay-text > h2 {
    line-height: 20px;
  }
}
