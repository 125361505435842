.container_img_home {
  display: block;
  width: 100%;
  height: 100vh;
  background-image: url("https://res.cloudinary.com/jmponz/image/upload/v1667955815/karynga/Logos%20Home/logo-bg-color-3_gdfcic.png");
  background-size: cover;
  animation: fade 1s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container_img_home_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .container_img_home {
    display: none;
  }

  .container_img_home_mobile {
    display: block;
    width: 100%;
    margin-top: 30px;
    height: 70vh;
    background-image: url("https://res.cloudinary.com/jmponz/image/upload/v1668266191/karynga/Logos%20Home/logo-bg-color-mobile-4_luh1qz.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: fade 1s ease-in-out;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
