.expo-title {
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.expo-subtitle {
  margin-bottom: 20px;
}

.text-expo {
  font-family: "Urbanist", sans-serif;
  line-height: 200%;
  font-size: 1.2rem;
  text-align: justify;
  padding-bottom: 50px;
  height: auto;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .text-expo {
    font-family: "Urbanist", sans-serif;
    line-height: 200%;
    width: 90%;
    margin: auto;
    text-align: center;
    padding-bottom: 100px;
  }
}
