.section-container-libros {
    width: 70%;
    float: left;
    margin-bottom: 50px;
    animation: fade 1.5s ease-in-out;
}

@keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

/* IMAGE */

.wrapper-libros {
    margin: auto;
    margin-top: 20px;
    margin-right: 50px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 2em;
}

.wrapper-libros img {
    width: 380px;
    height: 250px;
    padding-right: 20px;
    cursor: pointer;
    object-fit: cover;
}

.wrapper-libros h2 {
    display: block;
    font-size: .9rem;
    padding-top: 15px;
}

.wrapper-libros a {
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 768px) {

    .section-container-libros {
        width: 100%;
        margin: auto;
        clear: both;
        display: grid;
        justify-content: center;
    }

    .wrapper-libros {
        display: flex;
        margin: auto;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 0;
        gap: 0;
    }

    .wrapper-libros-images {
        margin: 50px;
        display: flex;
        justify-content: center;
    }

    .wrapper-libros-images img {
        margin-left: 20px;
        width: 350px;
        height: 200px
    }

    .wrapper-libros-images h2 {
        font-size: .8rem;
        text-align: center;
    }
}